// @ts-nocheck
import {createRouter, createWebHistory} from "vue-router"

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
        meta: {access: "public"},
    },
    {
        path: "/tailwind-cover-letter",
        name: "tailwind-cover-letter",
        component: () => import("@/views/CoverLetter.vue"),
    }
]

const router = createRouter({
    scrollBehavior() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({left: 0, top: 0})
            }, 500)
        })
    },
    history: createWebHistory(),
    routes: routes,
})

export default router
